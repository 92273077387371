<template>
  <section id="create-charge">
    <div class="container">
      <div class="text-primary">Captura de datos</div>

      <div class="box" v-if="!loading">
        <div class="charge-data-row justify-center">
          <div class="charge-data-item charge-amount align-center">
            <label for="" class="form-label text-center">Monto a Cobrar</label>
            <input
              type="number"
              v-model="amount"
              placeholder="0"
              v-on:keydown="validateNumberKeyPress($event)"
              class="amount-charge"
              min="1"
              :class="{
                'input-error': errors.amount.active,
              }"
            />
            <label v-if="errors.amount.active" class="label-error">
              {{ errors.amount.message }}
            </label>
          </div>
        </div>

        <div class="charge-data-row">
          <div class="charge-data-item charge-user-info">
            <label for="" class="form-label">Sucursal</label>
            <select v-model="selected_restaurant" class="form-input">
              <option disabled value="">Seleccionar</option>
              <option v-for="restaurant in restaurants" v-bind:value="restaurant.id" v-bind:key="restaurant.id">
                {{ restaurant.name }}
              </option>
            </select>
            <label v-if="errors.restaurant.active" class="label-error">
              {{ errors.restaurant.message }}
            </label>
          </div>
        </div>

        <div class="charge-data-row">
          <div class="charge-data-item charge-user-info">
            <label for="" class="form-label">Nombre del Tarjetahabiente</label>
            <input
              type="text"
              v-model="name"
              class="form-input"
              :class="{ 'input-error': errors.name.active }"
            />
            <label v-if="errors.name.active" class="label-error">
              {{ errors.name.message }}
            </label>
          </div>
        </div>
        <div class="charge-data-row">
          <div class="charge-data-item charge-email">
            <label for="" class="form-label">Correo electrónico</label>
            <input
              type="email"
              v-model="email"
              placeholder="ejemplo@email.com"
              class="form-input"
              :class="{ 'input-error': errors.email.active }"
            />
            <label v-if="errors.email.active" class="label-error">
              {{ errors.email.message }}
            </label>
          </div>
          <div class="charge-data-item charge-phone">
            <label for="" class="form-label">Teléfono</label>
            <input
              type="text"
              v-model="phone"
              v-on:keydown="validateNumberKeyPress($event)"
              placeholder="Número a 10 dígitos"
              :maxlength="10"
              pattern="\d*"
              inputmode="numeric"
              class="form-input"
              :class="{ 'input-error': errors.phone.active }"
            />
            <label v-if="errors.phone.active" class="label-error">
              {{ errors.phone.message }}
            </label>
          </div>
        </div>
        <div class="charge-data-row">
          <div class="charge-data-item charge-address">
            <label for="" class="form-label"
              >Domicilio (Calle, Número y Colonia)</label
            >
            <input
              type="text"
              v-model="address"
              class="form-input"
              :class="{ 'input-error': errors.address.active }"
            />
            <label v-if="errors.address.active" class="label-error">
              {{ errors.address.message }}
            </label>
          </div>
          <div class="charge-data-item charge-cp">
            <label for="" class="form-label">Código Postal</label>
            <input
              type="text"
              v-on:keydown="validateNumberKeyPress($event)"
              v-model="postal_code"
              :maxlength="5"
              inputmode="numeric"
              pattern="\d*"
              class="form-input"
              :class="{ 'input-error': errors.postal_code.active }"
            />
            <label v-if="errors.postal_code.active" class="label-error">
              {{ errors.postal_code.message }}
            </label>
          </div>
          <div class="charge-data-item charge-country">
            <label for="" class="form-label">País</label>
            <input
              type="text"
              v-model="country"
              class="form-input"
              :class="{ 'input-error': errors.country.active }"
            />
            <label v-if="errors.country.active" class="label-error">
              {{ errors.country.message }}
            </label>
          </div>
        </div>
        <!-- <div class="charge-data-row justify-center mt-10">
          <label class="checkbox-container">
            <input type="checkbox" v-model="isRecurrent" />
            <span class="checkmark"></span>
            Hacer un cargo recurrente
          </label>
        </div> -->
        <div class="charge-data-actions">
          <button
            @click.prevent="createCharge()"
            class="btn btn-primary btn-large"
          >
            Continuar
          </button>
          <router-link :to="{ name: 'Home' }" class="btn btn-no-bg btn-large"
            >Regresar</router-link
          >
        </div>
      </div>
      <loading v-else>
        Espere mientras recopilamos el listado de cargos.
      </loading>
    </div>
  </section>
</template>

<script>
import Loading from "@/components/Loading.vue";

export default {
  components: { Loading },
  data() {
    return {
      loading: false,
      hasError: "",
      isRecurrent: false,
      errors: {
        amount: { message: "Ingresa la cantidad a cobrar", active: false },
        name: { message: "Escribe un nombre válido", active: false },
        postal_code: {
          message: "Escribe un código postal válido",
          active: false,
        },
        country: { message: "Escribe un país válido", active: false },
        address: {
          message: "Escribe una dirección válida",
          active: false,
        },
        email: {
          message: "Tu correo debe tener formato ejemplo@email.com",
          active: false,
        },
        phone: {
          message: "El teléfono debe contener 10 dígitos",
          active: false,
        },
        restaurant: {
          message: "Debe seleccionar una sucursal para continuar.",
          active: false,
        }
      },
      amount: "",
      name: "",
      address: "",
      postal_code: "",
      country: "",
      email: "",
      phone: "",
      metadata: {},
      selected_restaurant:"",
      restaurants:[
          {
            id:"1",
            name:"Coacalco"
          },
          {
            id:"2",
            name:"Irapuato"
          },
          {
            id:"3",
            name:"Churubusco"
          },
          {
            id:"4",
            name:"Madero"
          },
          {
            id:"5",
            name:"Metepec"
          },
          {
            id:"6",
            name:"San Javier"
          },
          {
            id:"7",
            name:"San Marcos"
          },
          {
            id:"8",
            name:"Sendero"
          },
          {
            id:"9",
            name:"Tula"
          },
          {
            id:"10",
            name:"Villas"
          },
          {
            id:"11",
            name:"Zaragoza"
          }
        ]
    };
  },
  methods: {
    createCharge() {
      const self = this;
      self.resetErrors('amount');
      self.resetErrors('name');
      self.resetErrors('email');
      self.resetErrors('phone');
      self.resetErrors('address');
      self.resetErrors('postal_code');
      self.resetErrors('country');
      self.resetErrors('restaurant');
      if (this.validateForm()) {
        return;
      }
      this.loading = true;
      this.processMetadata();
      const clientInfo = {
        amount: this.amount,
        name: this.name,
        address: this.address,
        cp: this.postal_code,
        country: this.country,
        email: this.email,
        phone: this.phone,
        isRecurrent: this.isRecurrent,
        extensionId: this.$store.getters.user.extension,
        metadata:JSON.stringify(this.metadata)
      };
      const router = this.$router;
      this.loading = true;
      this.$store.dispatch("createCharge", clientInfo)
      router.push("/confirmar-cobro");
    },
    processMetadata(){
      //this customer's specific metadata
      //sub_account key/value will be handled in twilio.
      this.metadata = {
        sub_account : this.selected_restaurant
      }
    },
    validateForm() {
      this.hasError = false;
      const self = this;
      const clientInfo = {
        amount: this.amount,
        name: this.name,
        address: this.address,
        postal_code: this.postal_code,
        country: this.country,
        email: this.email,
        phone: this.phone,
      };
      console.log(clientInfo);
      Object.keys(clientInfo).forEach(function (key) {
        if (clientInfo[key] == "" || clientInfo[key] <= 5) {
          self.errors[`${key}`].active = true;
          self.hasError = true;
        }
      });

      if (!this.isValidEmail(this.email)) {
        self.errors.email.active = true;
        self.hasError = true;
      }
      if (!this.isValidPhone(this.phone)) {
        self.errors.phone.active = true;
        self.hasError = true;
      }

      if (!this.selected_restaurant){
        self.errors.restaurant.active = true;
        self.hasError = true;
      }

      return self.hasError;
    },
    isValidEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    isValidPhone(phone) {
      const regex = /[^0-9]/g;
      return phone.length >= 10 && !regex.test(phone);
    },
    resetErrors(element) {
      this.errors[element].active = false;
    },
    validateNumberKeyPress(e) {
      if(!((e.keyCode > 95 && e.keyCode < 106)
        || (e.keyCode > 47 && e.keyCode < 58) 
        || (e.keyCode == 8 || e.keyCode == 190 || e.keyCode == 9))) {
          e.preventDefault()
      }
    }
  },
  mounted() {
    if(this.$store.getters.confirmCharge) {
      const charge = this.$store.getters.confirmCharge;
      this.name = charge.name;
      this.email = charge.email;
      this.address = charge.address;
      this.postal_code = charge.cp;
      this.country = charge.country;
      this.phone = charge.phone;
      this.amount = charge.amount;
      this.metadata = charge.metadata;
      const sub_account = JSON.parse(charge.metadata);
      this.selected_restaurant = sub_account ? sub_account.sub_account : null;
      this.isRecurrent = charge.isRecurrent;
    }
  },
};
</script>

<style>
#create-charge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #737373;
}
.create-charge-data {
  display: flex;
  flex-direction: column;
  background-color: var(--bg-seconday-color);
  padding: 20px;
  margin: 10px 0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
}
.charge-data-row {
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
}

.charge-data-item {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.charge-amount {
  flex: 0 1 50%;
}
.charge-user-info {
  flex: 0 1 96%;
}
.charge-cp {
  flex: 0 0 15%;
  min-width: 1%;
}
.charge-country {
  flex: 0 0 20%;
  min-width: 1%;
}
.charge-address {
  flex: 0 0 55%;
}
.charge-email {
  flex: 0 1 55%;
}
.charge-phone {
  flex: 0 1 38%;
}
.charge-data-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
  font-size: 14px;
}
.input-error {
  border: solid 1px red;
  background: rgba(245, 9, 9, 0.15);
}
.label-error {
  color: rgba(245, 9, 9);
  font-size: 10px;
}
.amount-charge {
  border-left: none;
  border-right: none;
  border-top: none;
  box-shadow: none;
  border-bottom: solid;
  font-size: 40px;
  width: 50%;
  height: 5vh;
  text-align: center;
  color: #737373;
  background-image: url("~@/assets/images/icono_precio.png");
  background-position: 15px 5px;
  background-repeat: no-repeat;
  font-weight: lighter;
  padding-bottom: 10px;
  padding-top: 10px;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.text-center {
  text-align: center;
}
input::placeholder {
  color: #B9B9B9;
}
.input-error {
    border: solid 1px #B73E3E;
    background: #FFEFEF;
}
.input-without-background {
  background-image: none !important;
}
.form-label {
  font-weight: 400!important;
}
.form-input {
  color: #737373;
}
</style>
